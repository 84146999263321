import * as React from "react";
import styled from "styled-components";
import WMKPlaceholder from "../../../../common/placeholders/WMKPlaceholderBlogExcerpt";
import { colors, WMKGradient } from "../../../../../vars/palette";
import { WmkImage } from "wmk-image";
import { Blog } from "../../../../../classes/Blog";

const TitleWrap = styled.div`
  padding: 1rem 1rem 1rem 3rem;
  margin-top: 2rem;
  ${WMKGradient.grad()}
  width: 50%;
  color: ${colors.hex("reverse")};
  .date {
    font-size: 0.9rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  .title {
    font-size: 1rem;
  }
  @media only screen and (max-width: 991px) {
    padding: 2px 2px 2px 6px;
    .date {
      margin-bottom: 0;
    }
  }
  @media only screen and (max-width: 450px) {
    .date {
      font-size: 0.8rem;
    }
    .title {
      font-size: 0.9rem;
    }
  }
`;

const BlogTileContent = ({ blog }: { blog: Blog }) => {
  const now = new Date();
  const timestamp = blog.timestamp;
  const date = timestamp ? new Date(timestamp) : now;
  const dates = date.toString().split(" ");
  const image = blog.image;
  return (
    <>
      {image ? <WmkImage image={image} crop="tile" /> : <WMKPlaceholder />}
      <div className="inner-content">
        <TitleWrap>
          <p className="date">{`${dates[1]} ${dates[2]}`}</p>
          <h3 className="title">{blog.title}</h3>
        </TitleWrap>
      </div>
    </>
  );
};

export default BlogTileContent;
