import * as React from "react";
import styled from "styled-components";
import BlogComponent from "./SmallBlog";
import Placeholder from "../../../../common/placeholders/HalfPixels";
import { colors, WMKGradient } from "../../../../../vars/palette";
import { WmkLink } from "wmk-link";
import Theme from "../../../../../vars/ThemeOptions";
import { Blog } from "../../../../../classes/Blog";

const SmallWrap = styled.div`
  position: relative;
  margin: 1rem 0 0 0;
  overflow: hidden;
  .place {
    width: 100%;
    box-shadow: 0px 0px 10px ${colors.rgba("black", 0.25)};
  }
  .content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    .gatsby-image-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      height: 100% !important;
      width: 100% !important;
    }
    .inner-content {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      text-decoration: none;
    }
  }
  // HOVER ANIMATIONS
  &:hover {
    cursor: pointer;
    .hoverOverlay {
      right: 0;
      transition: right 0.5s ease;
    }
    .wipeAcross {
      left: 150%;
      transition: left 0.8s ease;
    }
  }
  .hoverOverlay {
    transition: right 0.5s ease;
    position: absolute;
    top: 0;
    right: -100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    background-color: ${colors.rgba("black", 0)};
    .learnMoreBar {
      position: absolute;
      z-index: 100;
      bottom: 10%;
      right: 0;
      width: 40%;
      height: 10%;
      color: ${colors.hex("white")};
      ${WMKGradient.grad()}
      padding: 0% 10%;
      p {
        font-family: ${Theme.font.head};
        font-weight: 600;
        font-size: 1rem;
        white-space: nowrap;
      }
    }
  }
  .wipeAcross {
    position: absolute;
    top: -30%;
    left: -30%;
    width: 200%;
    height: 250%;
    opacity: 0.5;
    transform: rotate(30deg);
    box-shadow: -35px 0px 100px ${colors.hex("primary")};
  }
`;

const BlogTile = ({ blog }: { blog: Blog }) => {
  return (
    <SmallWrap>
      <Placeholder />
      <WmkLink
        className="inner-content"
        to={blog.to}
        wrapperStyle={{ display: "block" }}
      >
        <div className="content">
          <BlogComponent blog={blog} />
        </div>
        <div className="hoverOverlay">
          <div className="learnMoreBar">
            <p>LEARN MORE</p>
          </div>
        </div>
        <div className="wipeAcross" />
      </WmkLink>
    </SmallWrap>
  );
};

export default BlogTile;
