import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Img, WmkImage } from "wmk-image";

const SquarePixel = () => {
  const squarePixel = useStaticQuery(graphql`
    {
      file(name: { eq: "squarepixel" }) {
        image: childImageSharp {
          gatsbyImageData(width: 600)
        }
        name
        publicURL
      }
    }
  `).file;
  let modifiedPixel = squarePixel;

  if (modifiedPixel?.image?.gatsbyImageData?.images?.sources[0]?.srcSet ) {
    let string1 = modifiedPixel.image.gatsbyImageData.images.sources[0].srcSet;
    string1 = string1.replace("undefinedw", "1w");
    modifiedPixel.image.gatsbyImageData.images.sources[0].srcSet = string1;

    let string2 = modifiedPixel.image.gatsbyImageData.images.fallback.srcSet
    string2 = string2.replace("undefinedw", "1w")
    modifiedPixel.image.gatsbyImageData.images.fallback.srcSet = string2
  }
  
  const im = new Img({
    title: modifiedPixel.name,
    file: { url: modifiedPixel.publicURL, contentType: "image/png" },
    ...modifiedPixel.image,
  });
  return <WmkImage className="square" image={im} />;
};

export default SquarePixel;
