import * as React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Blog } from "../../../../classes/Blog";
import { CaseStudy, CaseStudy3 } from "../../../../classes/CaseStudy";
import BigTile from "./BlockTilesBig";
import SmallTile from "./BlockTilesSmall/BlockTilesSmall";

const RowWrap = styled(Row)`
  &.flip {
    flex-direction: row-reverse;
  }
`;

type TilesRowProps = {
  work: CaseStudy | CaseStudy3;
  index: number;
  blogs: Blog[];
};

const TilesRow = ({ work, index, blogs }: TilesRowProps) => {
  const flipClass = index % 2 ? "flip" : undefined;
  return (
    <RowWrap className={flipClass}>
      <Col
        sm={12}
        md={6}
        style={{ margin: 0, padding: 0, position: "relative" }}
      >
        <BigTile work={work} />
      </Col>
      <Col sm={12} md={6}>
        <Row className="flex-column">
          <Col>
            <SmallTile blog={blogs[0]} />
          </Col>
          <Col>
            <SmallTile blog={blogs[1]} />
          </Col>
        </Row>
      </Col>
    </RowWrap>
  );
};

export default TilesRow;
