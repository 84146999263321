import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { WmkImage, Img } from "wmk-image";

const WMKPlaceholder = (props: { className?: string }) => {
  const image = useStaticQuery(graphql`
    {
      file(name: { eq: "wmk-placeholder" }) {
        image: childImageSharp {
          gatsbyImageData(width: 500, height: 240)
        }
        name
        publicURL
      }
    }
  `).file;
  const im = new Img({
    title: image.name,
    file: { url: image.publicURL, contentType: "image/png" },
    ...image.image,
  });
  return <WmkImage className={props?.className} image={im} />;
};

export default WMKPlaceholder;
