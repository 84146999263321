import * as React from "react";
import { Container } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import TilesRow from "./BlockTilesRow";
import { Blog, Blogs } from "../../../../classes/Blog";
import {
  CaseStudies,
  CaseStudy,
  CaseStudy3,
} from "../../../../classes/CaseStudy";
import {
  CaseStudyFields,
  CaseStudyV3Fields,
} from "../../../../fragments/nodeCaseStudyFields";
import { BlogFields } from "../../../../fragments/nodeBlogFields";

const TilesWrap = styled(Container)`
  margin-bottom: 3rem;
`;

type TilesQuery = {
  cases: { edges: { node: CaseStudyFields }[] };
  casesNew: { edges: { node: CaseStudyV3Fields }[] };
  blogs: { edges: { node: BlogFields }[] };
};

const Tiles = () => {
  const data: TilesQuery = useStaticQuery(graphql`
    {
      cases: allContentfulWorkCaseStudyOld(
        limit: 3
        sort: { fields: launchDate, order: DESC }
      ) {
        edges {
          node {
            ...NodeCaseStudyTileFields
          }
        }
      }
      casesNew: allContentfulCaseStudy(
        limit: 3
        sort: { fields: launchDate, order: DESC }
      ) {
        edges {
          node {
            ...NodeCaseStudyV3Fields
          }
        }
      }
      blogs: allContentfulWmkBlogs(
        limit: 6
        sort: { fields: publishDate, order: DESC }
      ) {
        edges {
          node {
            ...NodeBlogFields
          }
        }
      }
    }
  `);
  const blogs = new Blogs(data.blogs.edges);
  // remove any schema entries
  const noSchema = (entry: Blog | CaseStudy | CaseStudy3) => {
    return (
      !entry.slug.includes("schema")
    );
  };
  const blogsNoSchema = blogs && blogs.list.filter(noSchema);
  blogs.list = blogsNoSchema;
  // combine both new and old case study types -> list is now sorted by launch date
  const cases = new CaseStudies([...data.cases.edges, ...data.casesNew.edges]);
  // remove any schema entries
  const casesNoSchema = cases && cases.list.filter(noSchema);
  cases.list = casesNoSchema;
  // console.log('casesNoSchema: ', casesNoSchema);
  // console.log('cases: ', cases);
  // limit to 3 case studies
  const fullWorks = cases.list.slice(0, 3);
  // console.log('fullWorks: ', fullWorks);
  return (
    <TilesWrap>
      {fullWorks.length &&
        fullWorks.map((work, i) => {
          const blogIndex = i ? [i + i, i + i + 1] : [i, i + 1];

          return (
            <TilesRow
              key={"work-row-" + i}
              work={work}
              index={i}
              blogs={[blogs.list[blogIndex[0]], blogs.list[blogIndex[1]]]}
            />
          );
        })}
    </TilesWrap>
  );
};

export default Tiles;
