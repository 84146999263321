import * as React from "react";
import Theme from "../../../../vars/ThemeOptions";
import styled from "styled-components";
import Placeholder from "../../../common/placeholders/SquarePixel";
import { colors, WMKGradient } from "../../../../vars/palette";
import { WmkLink } from "wmk-link";
import { CaseStudy, CaseStudy3 } from "../../../../classes/CaseStudy";
import { WmkImage } from "wmk-image";

const TileWrap = styled.div`
  position: relative;
  margin: 1rem;
  overflow: hidden;
  .square {
    width: 100%;
    box-shadow: 0px 0px 10px ${colors.rgba("black", 0.25)};
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .square-blog.gatsby-image-wrapper,
  square-blog.gatsby-image-wrapper img {
    display: block !important;
    position: absolute !important;
    top: 0;
    left: 0;
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .title {
    margin: 2rem 0 0 0;
    padding: 1rem 3rem;
    width: 80%;
    color: ${colors.hex("reverse")};
    display: inline-block;
    ${WMKGradient.grad()}
  }
  // HOVER ANIMATIONS
  &:hover {
    .hoverOverlay {
      top: 0;
      transition: top 0.5s ease;
    }
    .wipeAcross {
      left: 150%;
      transition: left 0.6s ease;
    }
  }
  .hoverOverlay {
    transition: top 0.5s ease;
    position: absolute;
    top: 100%;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    background-color: ${colors.rgba("black", 0)};
    .viewCaseStudyBar {
      position: absolute;
      bottom: 13%;
      left: 0;
      width: 40%;
      height: 8%;
      color: ${colors.hex("white")};
      ${WMKGradient.grad()}
      padding: 1.75% 8%;
      p {
        font-family: ${Theme.font.head};
        font-weight: 600;
        font-size: 1rem;
        white-space: nowrap;
      }
    }
    .introCopyBar {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 13%;
      color: ${colors.hex("white")};
      background: ${colors.hex("black")};
      padding: 3.5% 8%;
      p {
        font-size: 1.1rem;
        white-space: nowrap;
      }
    }
  }
  .wipeAcross {
    position: absolute;
    top: -50%;
    left: -30%;
    width: 200%;
    height: 250%;
    opacity: 0.5;
    transform: rotate(30deg);
    box-shadow: -35px 0px 100px ${colors.hex("primary")};
  }
`;

const WorkTile = ({ work }: { work: CaseStudy | CaseStudy3 }) => {
  return (
    <TileWrap className="big-tile">
      <Placeholder />
      <WmkLink to={work.to}>
        <WmkImage image={work.heroImage} className="square-blog" />
        <div className="overlay">
          <h2 className="title">{work.clientName}</h2>
        </div>
        <div className="hoverOverlay">
          <div className="viewCaseStudyBar">
            <p>VIEW CASE STUDY</p>
          </div>
          <div className="introCopyBar">
            <p>{work.summary}</p>
          </div>
        </div>
        <div className="wipeAcross" />
      </WmkLink>
    </TileWrap>
  );
};

export default WorkTile;
