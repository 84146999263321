import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Theme from "../../vars/ThemeOptions";
import Button from "../common/GibsonButton";
import { colors, WMKGradient } from "../../vars/palette";

const CTAWrap = styled(Container)`
  margin-top: 2rem;
  .flex-column {
    align-items: center;
    .col {
      width: 210px;
      text-align: center;
      text-transform: uppercase;
      font-weight: 500;
      font-family: ${Theme.font.head};
      font-size: 1.5rem;
      letter-spacing: 0.08rem;
      &:not(:last-child) {
        ${WMKGradient.grad()}
        color: ${colors.hex("white")};
        padding: 0.25rem 0rem;
      }
      &:last-child {
        padding: 0;
      }
    }
  }
`;

type CallToActionProps = {
  label?: string;
  path?: string;
  url?: string;
  msg1?: string;
  msg2?: string;
};

export const CallToAction = ({
  label,
  path = "/contact",
  url,
  msg1 = "Let Us Tell",
  msg2 = "Your Story",
}: CallToActionProps) => {
  const messaging1 = msg1;
  const messaging2 = msg2;
  return (
    <CTAWrap>
      <Row className="flex-column">
        <Col>
          <div className="msg primary">{messaging1}</div>
        </Col>
        <Col>
          <div className="msg secondary">{messaging2}</div>
        </Col>
        <Col>
          <Button
            to={path ? path : url ? url : null}
            target={url ? "blank" : undefined}
            direction="right"
          >
            {label}
          </Button>
        </Col>
      </Row>
    </CTAWrap>
  );
};
