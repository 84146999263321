import * as React from "react";
import styled, { keyframes, Keyframes } from "styled-components";
import Video from "react-player";
import { colors } from "../../vars/palette";
import { StaticImage } from "gatsby-plugin-image";

const slideUpAnimation = keyframes`
0% { top: 0 }
90% { top: 0 }
100% { top: -100% }
`;

const slideDownAnimation = keyframes`
0% { bottom: 0 }
90% { bottom: 0 }
100% { bottom: -100% }
`;

const VidHeroWrap = styled.div`
  position: relative;
  background: ${colors.hex("black")};
  overflow: hidden;
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const StyledVideoCover = styled.div`
  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const StyledLetterBox = styled.div<{ anim: Keyframes }>`
  height: 10vh;
  background-color: ${colors.hex("white")};
  position: absolute;
  width: 100%;
  left: 0;
  animation-name: ${({ anim }) => anim};
  animation-duration: 8s;
  animation-iteration-count: 1;
`;

const HeroVideo = ({ url }: { url: string }) => {
  return (
    <VidHeroWrap>
      <Video
        url={url}
        width="100%"
        height="100%"
        className="react-player"
        playing={true}
        playsinline
        muted
        loop
      />
      {/* Covers the YouTube video so that YouTube branding/interactions do not show.*/}
      <StyledVideoCover>
        {/* Small transparent PNG that is the same aspect ratio as video but will scale to fit the space*/}
        <StaticImage src="../../images/trans16x9.png" alt="" />
      </StyledVideoCover>
      {/* Small elements that will cover up the initial YouTube branding until it fades away */}
      {/* <StyledLetterBox anim={slideUpAnimation} />
      <StyledLetterBox anim={slideDownAnimation} /> */}
    </VidHeroWrap>
  );
};

export default HeroVideo;
