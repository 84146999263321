import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { WmkImage, Img } from "wmk-image";

const HalfPixels = () => {
  const squarePixel = useStaticQuery(graphql`
    {
      file(name: { eq: "halfpixels" }) {
        image: childImageSharp {
          gatsbyImageData(width: 600)
        }
      }
    }
  `).file;
  const im = new Img({
    title: squarePixel.name,
    file: { url: squarePixel.publicURL, contentType: "image/png" },
    ...squarePixel.image,
  });
  return <WmkImage image={im} />;
};

export default HalfPixels;
